import React from "react"
import { Link } from "gatsby"
import { Fragment } from "react"

export default function IndexPage(

) {
  return (
      <Fragment>
            <head><title>404 - Ryan Schalk</title></head>
        <div className="centeredReadableContainer">
            <h1>Page Not Found</h1>
            <hr />
            <p>Ouch! The page you are looking for does not exist or has been moved. I hate when then happens.</p>
            <p>Would you like to view <Link to="/">a list of blog posts</Link> that do exist?</p>
        </div>
      </Fragment>
  )
}